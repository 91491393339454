import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import ScrollToTopIcon from '../components/ScrollToTopIcon';
import { ThemeProvider } from "@mui/material/styles";
import EntryBotton from "../components/EntryButton";

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ paddingBottom: "10%" }}>

        <Typography id="new-graduates" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
          新卒採用
        </Typography>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <ThemeProvider theme={colorTheme}>
              <Box
                sx={{
                  padding: {xs: "10% 5%", sm: "10% 5%", md: "5%"},
                  width: { xs: "80%", sm: "80%", md: "75%", lg: "65%", xl: "45%" },
                  margin: "0 auto",
                }}
              >
                <Typography
                  sx={{fontSize:{ xs: "5vw", sm: "5vw", md: "3vw", lg:"2vw", xl: "2vw" }}}
                  style={{ padding: "5% 0px 3% 0px" }}
                  fontWeight={"bold"}
                  textAlign={"left"}
                >
                  26卒向け選考直結型イベント<br />
                  <br />
                  <Typography sx={{ fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1.2vw", xl: "1vw" }, textAlign: 'left'  }}>
                    <div style={{ fontWeight:"bold" }}>＜26卒早期選考で、【プログラミング体験】にご招待！＞</div>
                    コラボテクノの若手エンジニアと一緒にプログラミング体験をしよう！<br />
                    <br />
                    ゲーム「テトリス」作りを体験しよう<br />
                    ・実施日程：<br />
                    　・2024年10月19日（土）、26日（土）<br />
                    　・2024年11月9日（土）、23日（土）<br />
                    　・2024年12月7日（土）<br />
                    　・2025年1月18日（土）<br />
                    ・実施時間：<br />
                    　13:00～16:30<br />
                    　13:00～15:30　プログラミング体験<br />
                    　15:30～16:30　コラボテクノの会社説明およびエンジニアから現場説明<br />
                    ・場所：東京都港区芝浦3丁目17-11天翔田町ビル1002号<br />
                    ・募集人員：最大4名／1回<br />
                    ・持ち物：ご自身のPCを持参ください<br />
                    ・服装：自由
                  </Typography>
                </Typography>
                <div>
                  <Box sx={{ marginTop: "10%" }}>
                    <EntryBotton text={"申し込む"} url="https://forms.gle/aYs9yXQ7kgH88G516"></EntryBotton>
                  </Box>
                </div>
              </Box>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
